import React from "react"

const DateDiff = ({ date }) => {
  if (!date) return null;
  const msDiff = new Date().getTime() - new Date(date).getTime();
  const diff = Math.floor(msDiff / (1000 * 60 * 60 * 24 * 365));
  return (
    diff > 0 ? <span> - {diff} Years </span> : null
  )
}

export default DateDiff
