import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const RecentBlogPosts = () => {

  const data = useStaticQuery(graphql`
    query {
      allNodeBlogPost(limit: 10, sort: {fields: created, order: DESC}) {
      edges {
          node {
          id
          title
          path {
            alias
          }
        }
      }
    }
  }
  `)

  return (
    <div className="recent-blog-posts">
      <ul className="employement-list" style={{ paddingTop: `20px` }}>
        {
          data.allNodeBlogPost.edges.map(node => {
            return (
              <li key={node.node.id} style={{ paddingBottom: '5px' }}>
                <Link to={node.node.path.alias}>
                  {node.node.title}
                </Link>
                <hr />
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default RecentBlogPosts
