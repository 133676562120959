import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import DateDiff from "../components/common/date-diff"
import RecentBlogPosts from "../components/common/recent-blog-posts"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allNodeResume {
      edges {
        node {
          id
          title
          field_meta_tags {
            title
            description
          }
          relationships {
            field_employment {
              id
              field_job_title
              field_to(formatString: "MMM YYYY")
              field_from(formatString: "MMM YYYY")
              field_job_company
              field_job_description {
                value
                format
                processed
              }
            }
            field_education {
              field_university
              field_degree
              id
              field_to(formatString: "MMM YYYY")
              field_from(formatString: "MMM YYYY")
            }
            field_skill {
              id
              field_skill_name
              field_starting_year
            }
            field_industry_sectors {
              id
              field_industry_name
            }
            field_languages {
              id
              field_langauge
            }
          }
        }
      }
    }
    userUser(drupal_internal__uid: {eq: 1}) {
      id
      field_user_name
      field_user_phone
      field_user_position
      field_user_email
      field_user_website {
        title
        uri
      }
      field_user_dob
      field_user_address
      field_user_introduction {
        value
        format
        processed
      }
      relationships {
        user_picture {
          localFile {
            childImageSharp {
              fluid(maxWidth: 240, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allNodeCertification(sort: {fields: title, order: ASC}) {
      edges {
        node {
          id
          relationships {
            field_badge {
              localFile {
                childImageSharp {
                  fixed(width: 120) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)

  return <Layout pageTitle={data.allNodeResume.edges[0].node.title} >

    <SEO title={data.allNodeResume.edges[0].node.field_meta_tags.title}
      description={data.allNodeResume.edges[0].node.field_meta_tags.description} />

    <div className="index-page">

      <div className="columns is-desktop">
        <div className="column is-three-quarters-desktop">
          <div className="res-section main-section">
            <div className="res-header" style={{ paddingBottom: `20px` }}>
              <div className="columns">
                <div className="column is-one-quarter-tablet is-half-mobile">
                  <Img fluid={data.userUser.relationships.user_picture.localFile.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <h2 className="title">{data.userUser.field_user_position}</h2>
                  <div className="user-data">
                    <p>Address: {data.userUser.field_user_address}</p>
                    <p>Phone: {data.userUser.field_user_phone}</p>
                    <p>Email: {data.userUser.field_user_email}</p>
                    <p>Website: {data.userUser.field_user_website.uri}</p>
                  </div>
                  <hr />
                  <div className="columns">
                    <div className="column certifications">
                      {
                        data.allNodeCertification.edges.map(cert => {
                          return (
                            <div key={cert.node.id} style={{ float: `left`, paddingRight: `20px` }}>
                              <Img fixed={cert.node.relationships.field_badge.localFile.childImageSharp.fixed} />
                            </div>
                          )
                        })
                      }
                      <div style={{ clear: `both` }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="user-summary" dangerouslySetInnerHTML={{ __html: data.userUser.field_user_introduction.value }}></div>
                </div>
              </div>
            </div>
            <div className="section-title">Employment</div>
            <hr />
            <ul className="employement-list" style={{ listStyle: `circle`, paddingLeft: `20px`, paddingTop: `20px` }}>
              {
                data.allNodeResume.edges[0].node.relationships.field_employment.map(node => {
                  return (
                    <li key={node.id} style={{ paddingBottom: '60px' }}>
                      <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                        <div style={{ fontSize: `20px`, fontWeight: 900 }}>
                          <p>{node.field_job_company}</p>
                        </div>
                        <div style={{ fontWeight: `normal`, fontSize: `14px` }}>
                          <FontAwesomeIcon icon={faCalendar} /> {node.field_from} - {node.field_to}
                        </div>
                      </div>
                      <div style={{ fontStyle: `italic` }}>
                        {node.field_job_title}
                      </div>
                      <div className="job-description" dangerouslySetInnerHTML={{ __html: node.field_job_description.processed }}>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="res-section">
            <div className="section-title">Education</div>
            <hr />
            <ul className="employement-list" style={{ listStyle: `circle`, paddingLeft: `20px`, paddingTop: `20px` }}>
              {
                data.allNodeResume.edges[0].node.relationships.field_education.map(node => {
                  return (
                    <li key={node.id} style={{ paddingBottom: '60px' }}>
                      <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                        <div style={{ fontSize: `20px`, fontWeight: 900 }}>
                          <p>{node.field_university}</p>
                        </div>
                        <div style={{ fontWeight: `normal`, fontSize: `14px` }}>
                          <FontAwesomeIcon icon={faCalendar} /> {node.field_from} - {node.field_to}
                        </div>
                      </div>
                      <div style={{ fontStyle: `italic` }}>
                        {node.field_degree}
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="res-section">
            <div className="section-title">Programming Skills</div>
            <hr />
            <ul className="employement-list" style={{ listStyle: `none` }}>
              {
                data.allNodeResume.edges[0].node.relationships.field_skill.map(node => {
                  return (
                    <li key={node.id} style={{ paddingBottom: '3px' }}>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <div style={{ fontSize: `14px`, textDecoration: `underline` }}>
                          <p>{node.field_skill_name}</p>
                        </div>
                        <div style={{ fontWeight: `normal`, fontSize: `14px` }}>
                          &nbsp;<DateDiff date={node.field_starting_year} />
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>

          <div className="res-section" style={{ marginTop: `30px` }}>
            <div className="section-title">Industry Sectors</div>
            <hr />
            <ul className="employement-list" style={{ listStyle: `none` }}>
              {
                data.allNodeResume.edges[0].node.relationships.field_industry_sectors.map(node => {
                  return (
                    <li key={node.id} style={{ paddingBottom: '3px' }}>
                      <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                        <div style={{ fontSize: `16px` }}>
                          <p>{node.field_industry_name}</p>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="res-section" style={{ marginTop: `30px` }}>
            <div className="section-title">Languages</div>
            <hr />
            <ul className="employement-list" style={{ listStyle: `none` }}>
              {
                data.allNodeResume.edges[0].node.relationships.field_languages.map(node => {
                  return (
                    <li key={node.id} style={{ paddingBottom: '3px' }}>
                      <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
                        <div style={{ fontSize: `16px` }}>
                          <p>{node.field_langauge}</p>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="res-section" style={{ marginTop: `30px` }}>
            <div className="section-title">Recent Blog Posts</div>
            <hr />
            <RecentBlogPosts />
          </div>
        </div>
      </div>
    </div>
  </Layout >
}


export default IndexPage



